import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { theme } from '../../styles/theme'
import { transientOptions } from '../style/transientOptions'
import * as React from 'react'
import { getCssProp, Styles } from '../../utilities/getCssProp'

export type LoadingSpinnerProps = { isSmall?: boolean; styles?: Styles; delay?: number; className?: string }
type StyledComponentProps = { $isSmall?: boolean; $show?: boolean }

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`
const dash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
`

const { cadetBlue, cerulean, aegean, grayMidnight } = theme.colors

const color = keyframes`
  100%,
  0% {
    stroke: ${grayMidnight};
  }
  40% {
    stroke: ${cadetBlue};
  }
  66% {
    stroke: ${aegean};
  }
  80%,
  90% {
    stroke: ${cerulean};
  }
`

export const loaderRadiusSmall = 35
export const loaderRadiusNormal = 85

const Container = styled('div', transientOptions)<StyledComponentProps>`
  position: relative;
  margin: 0 auto;
  width: ${(p) => (p.$isSmall ? loaderRadiusSmall : loaderRadiusNormal)}px;
  max-height: -webkit-fill-available;
  max-width: -webkit-fill-available;
  opacity: ${(p) => (p.$show ? 1 : 0)};
  transition: 0.5s opacity ease-in;
  cursor: progress;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`
const RotatingCircleContainer = styled.svg`
  animation: ${rotate} 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`

const Circle = styled('circle', transientOptions)<StyledComponentProps>`
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: ${dash} 1.5s ease-in-out infinite, ${color} 6s ease-in-out infinite;
  stroke-linecap: round;
  stroke-width: ${(p) => (p.$isSmall ? 3 : 2)}px;
  stroke-miterlimit: 10px;
`

export const useShowLoadingAfterDelay = (delay: number = 0) => {
  const [show, setShow] = React.useState(!delay)
  React.useEffect(() => {
    let unmounted = false
    const timeout = setTimeout(() => !unmounted && setShow(true), delay)
    return () => {
      clearTimeout(timeout)
      unmounted = true
    }
  }, [delay])
  return show
}

export function EagerLoadingSpinner({ isSmall, styles, delay = 500, className }: LoadingSpinnerProps) {
  const css = getCssProp(styles)
  const $show = useShowLoadingAfterDelay(delay)
  return (
    <Container $isSmall={isSmall} css={css} $show={$show} className={className}>
      <RotatingCircleContainer viewBox="25 25 50 50">
        <Circle cx="50" cy="50" r="20" fill="none" $isSmall={isSmall} />
      </RotatingCircleContainer>
    </Container>
  )
}
